// variable scss
@import "partials/variables";

// mixin scss
@import "partials/mixin";
@import "partials/keyframes";

// common scss
@import "partials/common";
// button scss
@import "partials/button";
// icon scss
@import "partials/icon";
// avatar scss
@import "partials/avatar";
// general scss
@import "partials/general";
// header scss
@import "partials/header";
// breadcrumb scss
@import "partials/breadcrumb";
// contact scss
@import "partials/contact";
// hero scss
@import "partials/hero";
// cards scss
@import "partials/cards";
// countdown scss
@import "partials/countdown";
// counter scss
@import "partials/counter";
// tabs scss
@import "partials/tabs";
// testimonial scss
@import "partials/testimonial";
// gallery scss
@import "partials/gallery";
// collapse scss
@import "partials/collapse";
// team-member scss
@import "partials/team-member";
// sidebar scss
@import "partials/sidebar";
// blog scss
@import "partials/blog";
// comment scss
@import "partials/comment";




