/*---------------- container ----------------*/
.container {
  max-width: 1200px;
}
@media (min-width:576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width:768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width:992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width:1200px) {
  .container {
    max-width: 1200px;
  }
}
/*========== badge ==========*/
.badge-md {
  padding: 6px 12px;
  font-size: $font-14;
}
/*========== opacity ==========*/
.opacity-8 {
  opacity: 0.8!important;
}
/*========== position ==========*/
.top-0 {
  top: 0!important;
}
.right-0 {
  right: 0!important;
}
.bottom-0 {
  bottom: 0!important;
}
.left-0 {
  left: 0!important;
}
/*========== z-index ==========*/
.z-index-0 {
  z-index: 0!important;
}
.z-index-1 {
  z-index: 1!important;
}
.z-index-2 {
  z-index: 2!important;
}
/*========== hover effect ==========*/
.hover-y {
  @include transition(0.3s);
  &:hover {
    @include transform(translateY(-4px));
  }
}
/*========== border color ==========*/
.border-gray {
  border-color: rgba(128, 137, 150, 0.1)!important;
}
.border-top-gray {
  border-top-color: rgba(128, 137, 150, 0.1)!important;
}
.border-right-gray {
  border-right-color: rgba(128, 137, 150, 0.1)!important;
}
.border-bottom-gray {
  border-bottom-color: rgba(128, 137, 150, 0.1)!important;
}
.border-left-gray {
  border-left-color: rgba(128, 137, 150, 0.1)!important;
}
/*========== color ==========*/
.text-black {
  color: $theme-color!important;
}
.colorLightSlateGrey {
  color: $GhostWhite!important;
}
.colorBlueViolet {
  color: $blueViolet!important;
}
.colorDarkOrange {
  color: $darkOrange!important;
}
.colorDeepSkyBlue {
  color: $deepSkyBlue!important;
}
.colorLimeGreen {
  color: $limeGreen!important;
}
.colorYellow {
  color: $yellow!important;
}

/*========== bg ==========*/
.bg-gradient {
  background: #f68a03!important;
  background: -moz-linear-gradient(-45deg,  #f68a03 0%, #f4c58b 100%)!important;
  background: -webkit-linear-gradient(-45deg,  #f68a03 0%,#f4c58b 100%)!important;
  background: linear-gradient(135deg,  #f68a03 0%,#f4c58b 100%)!important;
}
.bgGhostWhite {
  background-color: $GhostWhite!important;
}
.bg-blue {
  background-color: $blue!important;
  border-color: $blue!important;
}
.bg-cyan {
  background-color: $cyan!important;
  border-color: $cyan!important;
}

.bg-img {
  background-image: url(../images/promo-img.jpg);
  background-size: cover;
  background-position: center;
}
.bg-img-2 {
  background-image: url(../images/cta-img.jpg);
  background-size: cover;
  background-position: center;
}

/*========== font-size ==========*/
.font-120 {
  font-size: 120px!important;
}
.font-100 {
  font-size: 100px!important;
}
.font-90 {
  font-size: 90px!important;
}
.font-80 {
  font-size: 80px!important;
}
.font-60 {
  font-size: 60px!important;
}
.font-50 {
  font-size: 50px!important;
}
.font-24 {
  font-size: 24px!important;
}
.font-20 {
  font-size: 20px!important;
}
.font-18 {
  font-size: 18px!important;
}
.font-15 {
  font-size: 15px!important;
}
.font-14 {
  font-size: 14px!important;
}
/*-=========== font-weight =============-*/
.fw-medium {
  font-weight: $font-weight-medium!important;
}
/*-=========== spacing =============-*/
.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}
.section--padding {
  padding-top: 100px;
  padding-bottom: 70px;
}
/* padding top */
.pt-120 {
  padding-top: 120px!important;
}
.pt-100 {
  padding-top: 100px!important;
}
.pt-90 {
  padding-top: 90px!important;
}
.pt-80 {
  padding-top: 80px!important;
}
.pt-70 {
  padding-top: 70px!important;
}
/* padding bottom */
.pb-190 {
  padding-bottom: 190px!important;
}
.pb-120 {
  padding-bottom: 120px!important;
}
.pb-100 {
  padding-bottom: 100px!important;
}
.pb-90 {
  padding-bottom: 90px!important;
}
.pb-80 {
  padding-bottom: 80px!important;
}
.pb-70 {
  padding-bottom: 70px!important;
}


