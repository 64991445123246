.breadcrumb {
  background-color: transparent;
  @include border-radius(0);
  margin: 0;
  padding: 0;
  font-weight: $font-weight-medium;
}
.breadcrumb-item {
  a {
    color: $white;
    @include transition(0.1s);
    &:hover {
      color: $darkOrange;
      text-decoration: underline;
    }
  }
  +.breadcrumb-item {
    &:before {
      content: "\f105";
      font-family: "Font Awesome 5 Pro";
      vertical-align: middle;
      color: $white;
    }
  }
  &.active {
    color: rgba(255,255,255, 0.7);
  }
}