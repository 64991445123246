.hero-bg-1 {
  background-image: url(../images/hero-img1.jpg);
}
.hero-bg-2 {
  background-image: url(../images/hero-img2.jpg);
}
.hero-bg-3 {
  background-image: url(../images/hero-img3.jpg);
}
.hero-bg-4 {
  background-image: url(../images/hero-img4.jpg);
}
.hero-bg-5 {
  background-image: url(../images/hero-img5.jpg);
}
.hero-item {
  padding-top: 250px;
  padding-bottom: 250px;
  position: relative;
  @media #{$tab_device} {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  @media #{$large_mobile} {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  @media #{$small_mobile} {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.hero-slider {
  .owl-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    @include transform(translateX(-50%));
  }
}