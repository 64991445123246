* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
a:hover,
a:focus{
  text-decoration: none;
}
button:focus,
input:focus {
  outline: none;
}
h1, h2, h3, h4, h5, h6 {
  color: $theme-color;
  font-family: $theme-font-2;
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
body {
  font-size: $font-16;
  line-height: 24px;
  font-family: $theme-font;
  color: $lightSlateGrey;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
/*-=========== loader =============-*/
.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $white;
  top: 0;
  left: 0;
  z-index: 99999;
  div {
    width: 80px;
    height: 80px;
    @include perspective(120px);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    @include transform(translate(-50%, -50%));
    &:before {
      content: "";
      position: absolute;
      width: 40px;
      height: 40px;
      background-color: $darkOrange;
      top: 15px;
      left: 15px;
      animation: flip 1s infinite;
    }
  }
}
/*-=========== overlay =============-*/
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: $black;
  opacity: .6;
  z-index: -1;
}
/*-=========== pattern-bg =============-*/
.pattern-bg {
  position: relative;
  z-index: 1;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/line-shape.png);
    background-size: cover;
    background-position: center;
    z-index: 1;
    opacity: .2;
    pointer-events: none;
  }
}
/*-=========== title-shape =============-*/
.title-shape {
  border-bottom: 1px solid rgba(128, 137, 150, 0.1);
  height: 1px;
  span {
    display: block;
    width: 50px;
    height: 1px;
    background-color: $darkOrange;
  }
}
/*-=========== spacing =============-*/
.sec-title {
  font-size: $font-50;
  font-weight: $font-weight-semi-bold;
  @media #{$small_mobile} {
    font-size: $font-30;
  }
  &.font-60,
  &.font-80,
  &.font-90,
  &.font-120 {
    @media #{$tab_device} {
     font-size: $font-50!important;
    }
    @media #{$large_mobile} {
     font-size: $font-50!important;
    }
    @media #{$small_mobile} {
     font-size: $font-30!important;
    }
  }
}
.sec-desc {
  font-size: $font-18;
  line-height: 28px;
  @media #{$small_mobile} {
    font-size: $font-16;
  }
}
/*-=========== list-item-white =============-*/
.list-item {
  li {
    margin-bottom: 7px;
    a {
      color: $lightSlateGrey;
      @include transition(0.2s);
      &:hover {
        color: $darkOrange;
      }
    }
  }
}
/*-=========== list-item-white =============-*/
.list-item-white {
  li {
    color: $white;
    a {
      color: $white;
    }
  }
}
/*-=========== owl-theme =============-*/
.owl-theme {
  .owl-dots {
    .owl-dot {
      span {
        border: 2px solid $lightGray;
        background: transparent;
        width: 14px;
        height: 14px;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        &::after {
          position: absolute;
          content: '';
          top: 2px;
          left: 2px;
          width: 6px;
          height: 6px;
          @include border-radius(100%);
          background-color: transparent;
          @include transform(scale(0));
          @include transition(0.3s);
        }
      }
      &:hover,
      &.active {
        span {
          border-color: $darkOrange;
          background: transparent;
          &::after {
            @include transform(scale(1));
            background-color: $darkOrange;
          }
        }
      }
    }
  }
}
/*========= custom-control-input ==========*/
.custom-control-input {
  &:checked {
    ~ .custom-control-label {
      &:before {
        background-color: $darkOrange;
        border-color: $darkOrange;
      }
    }
  }
  &:focus {
    &:not(:checked) {
      ~ .custom-control-label {
        &:before {
          border-color: $darkOrange;
        }
      }
    }
    ~ .custom-control-label {
      &:before {
        @include box-shadow(0 0 0 0.2rem rgba(246, 138, 3, .25));
      }
    }
  }
}
/*===== blockquote =====*/
.blockquote {
  border-left: 5px solid $GhostWhite;
  padding: 10px 20px;
  font-size: $font-18;
}
/*======= pagination-list ========*/
.pagination-list {
  .page-item {
    &.active {
      .page-link {
        background-color: $darkOrange;
        &:hover {
          color: $white;
        }
      }
    }
    &:first-child {
      .page-link {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
    &:last-child {
      .page-link {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
  .page-link {
    margin-left: 3px;
    border: 0;
    @include border-radius(6px);
    color: $lightSlateGrey;
    &:hover {
      color: $theme-color;
    }
  }
}
/*-=========== list-group =============-*/
.list-group-flush {
  .list-group-item {
    padding-left: 0;
    padding-right: 0;
  }
}
.list-group-item {
  border-bottom-color: rgba(128, 137, 150, 0.1);
  a {
    color: $lightSlateGrey;
    @include transition(0.1s);
    &:hover {
      color: $darkOrange;
    }
  }
}
/*-=========== back-to-top =============-*/
#back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  @include transition(0.3s);
  opacity: 0;
  visibility: hidden;
  &.active {
    opacity: 1;
    visibility: visible;
  }
  &:hover {
    background-color: $darkOrange;
    color: $white
  }
}